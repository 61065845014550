import { Routes, Route, BrowserRouter } from 'react-router-dom'

// Pages
import PrivateRoute from './components/PrivateRoute'
import NotFound from './pages/errors/NotFound'
import SignInPage from './pages/signin'
import HomePage from './pages/home'
import OrderPage from 'pages/order/list'
import CreateOrderPage from 'pages/order/create'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/home' element={<PrivateRoute component={HomePage} />} />
        <Route path='/orders'>
          <Route index element={<PrivateRoute component={OrderPage} />} />
          <Route path='create' element={<PrivateRoute component={CreateOrderPage} />} />
          <Route path='edit/:orderId' element={<PrivateRoute component={OrderPage} />} />
        </Route>

        <Route index element={<SignInPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
