import { createElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu, Modal, MenuProps } from 'antd'
import { ExclamationCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { useGlobalStore } from '../../../stores/global'
import { useAuth } from '../../../stores/authorized'

const { confirm } = Modal
const Navbar = () => {
  const navigate = useNavigate()
  const { collapsed, setCollapsed } = useGlobalStore()
  const { auth, setAuth } = useAuth()

  const signOut = () => {
    confirm({
      title: 'ต้องการออกจากระบบ',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการล๊อกออกจากระบบ',
      centered: true,
      onOk() {
        setAuth(undefined)
        sessionStorage.clear()
        navigate('/signin')
      },
    })
  }

  const items: MenuProps['items'] = [
    {
      label: auth?.user.name,
      key: 'infomenu',
      children: [
        {
          key: 'infomation',
          label: <span>ข้อมูลส่วนตัว</span>,
        },
        {
          key: 'password',
          label: <span>เปลี่ยนรหัสผ่าน</span>,
        },
        {
          key: 'signout',
          label: <span onClick={signOut}>ออกจากระบบ</span>,
        },
      ],
    },
  ]

  return (
    <Layout.Header className='site-layout-background py-0 px-4'>
      <div className='float-left flex h-full py-2'>
        <div className='list-unstyled list-inline text-2xl text-teal-400'>
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div>
      </div>

      <div className='float-right'>
        <div className='list-unstyled list-inline'>
          <Menu items={items} mode='horizontal' defaultSelectedKeys={['infomenu']} />
        </div>
      </div>
    </Layout.Header>
  )
}

export default Navbar
