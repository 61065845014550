import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../stores/authorized'
import './SignIn.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const SignInPage = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (loading === true) {
      setTimeout(() => {
        setLoading(false)
        navigate('/home')
      }, 2000)
    }
  }, [loading])

  const signIn = () => {
    setLoading(true)
    // Example set value to store state for login user
    setAuth({
      user: {
        title: 'M.r.',
        firstName: 'Lotuss',
        lassName: 'developer',
        name: 'CORPORATE CUSTOMER',
        email: 'lotuss-developer@lotuss.com',
      },
      isLoggedIn: true,
      authorized: {
        accessToken: 'accessToken',
        refreshToken: 'refreshToken',
        expiresIn: 300,
        tokenType: 'bearer',
        scope: 'full_access',
      },
    })
  }

  return (
    <>
      <div className='flex py-16 bg-gradient-to-br from-sky-50 to-gray-200 min-h-screen'>
        <div className='relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40'>
          <div className='m-auto md:w-8/12 lg:w-6/12 xl:w-6/12'>
            <Spin spinning={loading} indicator={antIcon}>
              <div className='rounded-xl bg-white shadow-xl'>
                <div className='p-6 pt-0 sm:p-16 '>
                  <div className='space-y-4 p-5 rounded-lg'>
                    <img
                      src='https://cdn.onelogin.com/images/brands/logos/login/944e594d94bba5b81563318c3753f491477ed1bf.png?1622217552'
                      loading='lazy'
                      className='w-30 m-auto'
                      alt='tailus logo'
                    />
                  </div>
                  <div className='mt-16 grid space-y-4'>
                    <button
                      className='group h-12 px-6 border-2 border-transparent bg-teal-400 hover:bg-white rounded-full transition duration-300 
                                     hover:border-teal-300 focus:bg-teal-100 active:bg-teal-100'
                      onClick={signIn}
                    >
                      <div className='relative flex items-center space-x-4 justify-center'>
                        <span className='block w-max font-semibold tracking-wide text-white text-sm transition duration-300 group-hover:text-teal-400 sm:text-base'>
                          Continue with lotus one login
                        </span>
                      </div>
                    </button>
                  </div>

                  <div className='mt-24 space-y-4 text-gray-600 text-center sm:-mb-8'>
                    <p className='text-xs'>
                      By proceeding, you agree to our <br />
                      <span className='underline '>Terms of Use</span> and confirm you have read our{' '}
                      <span className='underline'>Privacy and Cookie Statement</span>.
                    </p>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInPage
