import { useEffect } from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'

const CreateOrderPage = () => {
  const { setPageHeader } = useGlobalStore()

  useEffect(() => {
    setPageHeader({
      title: 'Order Management',
      subTitle: 'Create Order',
      onBack: '/orders',
      backIcon: <HomeOutlined />,
      extra: [],
    })
  }, [])

  return <></>
}

export default CreateOrderPage
