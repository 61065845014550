import { Navigate, useLocation } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd'
// Components
import Content from './layouts/content'
import Footer from './layouts/footer'
import Navbar from './layouts/navbar'
import Sidebar from './layouts/sidebar'

import { useAuth } from '../stores/authorized'

const PrivateRoute = ({ component: Component }: any) => {
  const location = useLocation()
  const { auth } = useAuth()

  if (!auth?.isLoggedIn) {
    return <Navigate replace to='/signin' state={{ from: location.pathname }} />
  }

  return (
    <ConfigProvider>
      <Layout id='app-layout' className='min-h-screen'>
        <Sidebar />
        <Layout className='site-layout'>
          <Navbar />
          <Content>
            <Component />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default PrivateRoute
