import create from 'zustand'
import { PageHeader } from './types/global.types'

type State = {
  collapsed: boolean
  setCollapsed: (toggle: boolean) => void // set toggle menu
  pageHeader: PageHeader
  setPageHeader: (pageHeader: PageHeader) => void
}

export const useGlobalStore = create<State>((set) => ({
  collapsed: false,
  setCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),

  pageHeader: {
    title: undefined,
    subTitle: undefined,
    backIcon: false,
    onBack: undefined,
    extra: [],
  },
  setPageHeader: (pageHeader) =>
    set((state) => ({
      pageHeader: {
        ...state.pageHeader,
        ...pageHeader,
      },
    })),
}))
