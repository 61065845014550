import create from 'zustand'
import { persist } from 'zustand/middleware'
import { Auth } from './types/authorized.types'

type State = {
  auth: Auth | undefined
  setAuth: ($auth: any) => void
  accessToken: string | undefined
  setAccessToken: ($token: string | undefined) => void
}

export const useAuth = create(
  persist<State>(
    (set) => ({
      auth: {
        user: undefined,
        isLoggedIn: false,
        authorized: {
          accessToken: undefined,
          refreshToken: undefined,
          expiresIn: undefined,
          tokenType: undefined,
          scope: undefined,
        },
      },
      setAuth: ($auth: Auth) => {
        set({ auth: $auth })
      },

      accessToken: undefined,
      setAccessToken: ($token = undefined) => {
        set({ accessToken: $token })
      },
    }),
    {
      name: 'authorized',
      getStorage: () => sessionStorage,
    },
  ),
)
