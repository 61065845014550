import { useEffect } from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import './Order.scss'

const OrderPage = () => {
  const { setPageHeader } = useGlobalStore()

  useEffect(() => {
    setPageHeader({
      title: 'Order Management',
      subTitle: '',
      onBack: '/orders',
      backIcon: <HomeOutlined />,
      extra: [],
    })
  }, [])

  return <></>
}

export default OrderPage
