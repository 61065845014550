import { Link } from 'react-router-dom'
import { Layout, Menu, MenuProps } from 'antd'
import { UnorderedListOutlined, HomeOutlined } from '@ant-design/icons'
import { useGlobalStore } from '../../../stores/global'

const { Sider } = Layout

const LinkMenu = ({ path, name }: { path: string; name: string }) => (
  <Link to={path}>
    <span>{name}</span>
  </Link>
)

const items: MenuProps['items'] = [
  {
    key: 'Home',
    icon: <HomeOutlined />,
    label: <LinkMenu path='/home' name='Home' />,
  },
  {
    key: 'Order',
    icon: <UnorderedListOutlined />,
    label: <LinkMenu path='/orders' name='Order' />,
  },
]

const Sidebar = () => {
  const { collapsed, setCollapsed } = useGlobalStore()

  return (
    <Sider
      collapsible
      collapsed={collapsed as boolean}
      onCollapse={() => setCollapsed(!collapsed)}
      id='app-sidenav'
    >
      <section className='sidenav-header'>
        <div className='logo subpixel-antialiased font-bold text-center text-white text-xl h-8 m-4 flex justify-center items-center'>
          {collapsed ? 'GC' : 'GIFT CARD'}
        </div>
      </section>

      <div className='sidenav-content'>
        <Menu theme='dark' mode='inline' items={items} />
      </div>
    </Sider>
  )
}

export default Sidebar
